import React, { useEffect, useRef } from "react";
import Services from "./services/Service";
import About from "./about/About";
import Project from "./projects/Project";
import WhyChooseUs from "./chooseus/WhyChooseUs";
import Review from './reviews/Review';
import Footer from "./Footer";

function Hero() {
    const canvasRef = useRef(null);
    const textLines = ["function main() {", " console.log('Hello world')", "}"];
    let currentLineIndex = 0;
    let currentTextIndex = 0;

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!(canvas instanceof HTMLCanvasElement)) {
            console.error("Canvas element not found or incorrect element type");
            return;
        }

        const ctx = canvas.getContext("2d");
        const backgroundImage = new Image();
        backgroundImage.src = "/assets/images/layout-img.jpg"
        backgroundImage.onload = () => {
            if (backgroundImage.complete) {
                requestAnimationFrame(animate);
            }
        };
        if (!ctx) {
            console.error("Unable to get canvas context");
            return;
        }

        // Set canvas size to match the screen size
        const setCanvasSize = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

        setCanvasSize();
        window.addEventListener("resize", setCanvasSize);

        // Particle setup
        const particles = [];
        const particleCount = 20; // Reduced particle count

        class Particle {
            constructor(x, y, vx, vy) {
                this.x = x;
                this.y = y;
                this.vx = vx;
                this.vy = vy;
                this.size = 5;
            }

            draw() {
                ctx.fillStyle = "rgba(0, 128, 255, 0.9)"; // Soft, bright blue color
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.size / 2, 0, Math.PI * 2);
                ctx.fill();
            }

            update() {
                this.x += this.vx;
                this.y += this.vy;

                // Wrap particles around the edges of the screen
                if (this.x < 0) this.x = canvas.width;
                if (this.x > canvas.width) this.x = 0;
                if (this.y < 0) this.y = canvas.height;
                if (this.y > canvas.height) this.y = 0;

                this.draw();
            }
        }

        // Initialize particles
        for (let i = 0; i < particleCount; i++) {
            particles.push(
                new Particle(
                    Math.random() * canvas.width,
                    Math.random() * canvas.height,
                    (Math.random() - 0.5) * 1, // Velocity
                    (Math.random() - 0.5) * 1
                )
            );
        }

        // Animation loop
        const animate = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

            // Draw lines to form a cube shape
            ctx.beginPath();
            for (let i = 0; i < particles.length; i++) {
                for (let j = i + 1; j < particles.length; j++) {
                    const dx = particles[i].x - particles[j].x;
                    const dy = particles[i].y - particles[j].y;
                    const distance = Math.sqrt(dx * dx + dy * dy);

                    if (distance < 150) { // Increased distance to make lines more visible
                        ctx.moveTo(particles[i].x, particles[i].y);
                        ctx.lineTo(particles[j].x, particles[j].y);
                    }
                }
            }
            ctx.strokeStyle = "rgba(255, 255, 255, 0.5)";
            ctx.lineWidth = 1;
            ctx.stroke();

            particles.forEach((particle) => particle.update());
            requestAnimationFrame(animate);
        };

        animate();

        // Typewriter effect for console text
        const typeText = () => {
            if (currentTextIndex < textLines[currentLineIndex].length) {
                currentTextIndex++;
            } else {
                currentTextIndex = 0;
                currentLineIndex = (currentLineIndex + 1) % textLines.length;
            }
            setTimeout(typeText, 200);
        };

        typeText();

        // Cleanup function
        return () => {
            window.removeEventListener("resize", setCanvasSize);
        };
    }, []);

    return (
        <div className="hero-content relative h-screen w-full text-white overflow-y-scroll">
            <canvas
                ref={canvasRef}
                className="absolute top-0 left-0 w-full h-screen md:h-full"
                style={{ zIndex: -1 }}
            />
            <div className="p-16 sm:p-16 md:p-32 h-full">
                <div className="section-title mt-16 md:mt-10">
                    <h3 className="text-xl md:text-3xl font-bold mb-2 md:mb-4 text-white">
                        Professional in Web Design & Development
                    </h3>
                    <h1 className="text-2xl md:text-5xl font-bold mb-2 md:mb-4 text-white">
                        Web & Application Development Services
                    </h1>
                    <p
                        className="text-sm sm:text-base md:text-lg mb-4 md:mb-8 max-w-xl text-white p-2 md:text-justify"
                        style={{ backgroundColor: "rgba(26, 26, 26, 0.8)" }} // Semi-transparent dark gray background
                    >
                        We provide professional web design and development solutions, optimizing user experience and enhancing your business efficiency. Explore our landing page and web application development services.
                    </p>
                    <div className="flex flex-col sm:flex-row justify-start space-y-2 sm:space-y-0 sm:space-x-4">
                        <button className="bg-gray-400 px-4 py-2 text-base md:text-lg font-semibold hover:bg-gray-500">
                            Contact Us
                        </button>
                        <button className="bg-gray-800 px-4 py-2 text-base md:text-lg font-semibold hover:bg-gray-900">
                            View Portfolio
                        </button>
                    </div>
                </div>
            </div>

            <div className="section-content bg-white" id="services">
                <div className="mt-10 md:mt-20 pl-4 md:!pl-20 pr-6">
                    <Services />
                </div>
            </div>

            {[
                { Component: About, spacing: "mt-10" },
                { Component: Project, spacing: "mt-10" },
                { Component: WhyChooseUs, spacing: "mt-10" },
                { Component: Review, spacing: "mt-10" },
            ].map(({ Component, spacing }, index) => (
                <div className={`section-content ${spacing} pl-4 md:!pl-20 pr-6 bg-white`} key={index}>
                    <Component />
                </div>
            ))}

            <div className="section-content bg-white">
                <div className="mt-10 md:mt-20 pl-4 md:!pl-20 pr-6">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Hero;
