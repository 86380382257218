import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin, faTwitter, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane, faCogs, faInfoCircle, faThumbsUp, faStar, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    const [showPopup, setShowPopup] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowPopup(true); // Hiển thị popup khi nhấn nút Send
    };

    const closePopup = () => {
        setShowPopup(false); // Đóng popup
    };

    return (
        <footer className="bg-gray-50 py-8 px-4 grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left" id='contact'>
            {/* Left Column: Logo, Social Icons, and Copyright */}
            <div className="flex flex-col items-center md:items-start">
                <img src="https://hopee.com.vn/wp-content/themes/hopee_blog/assets/front/images/logo-black.svg" alt="Anh Duong Logistics" className="h-16 mb-4" />
                <p className="text-lg font-bold ">T&K Technology</p>
                <p className="text-xs text-gray-500 mb-4">Since 2022</p>

                {/* Social Icons */}
                <div className="flex space-x-4 mb-4">
                    <a href="https://www.facebook.com/profile.php?id=100008542713746" target="_blank" rel="noopener noreferrer" className="text-black hover:text-blue-600">
                        <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </a>
                    <a href="https://www.linkedin.com/in/nguy%E1%BB%85n-kh%C3%A1nh-985889232" target="_blank" rel="noopener noreferrer" className="text-black hover:text-blue-600">
                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-black hover:text-blue-600">
                        <FontAwesomeIcon icon={faTwitter} size="2x" />
                    </a>
                </div>

                <p className="text-xs text-gray-500">© 2023 Digital Transfom TNK Company</p>
            </div>
            {/* Right Column: Contact Form */}
            <div className="flex flex-col items-center md:items-start">
                <h2 className="text-lg font-semibold  mb-4">Contact Us</h2>
                <form className="flex flex-col w-full max-w-md" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Your Name"
                        className="mb-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 text-black focus:ring-sky-300"
                    />
                    <input
                        type="email"
                        placeholder="Your Email"
                        className="mb-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 text-black focus:ring-sky-300"
                    />
                    <textarea
                        placeholder="Content"
                        className="mb-2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 text-black focus:ring-sky-300"
                        rows="3"
                    />
                    <button
                        type="submit"
                        className="flex items-center justify-center p-2 bg-sky-500 text-white rounded-lg hover:bg-sky-600"
                    >
                        Send <FontAwesomeIcon icon={faPaperPlane} className="ml-2" />
                    </button>
                </form>
            </div>

           {/* Popup */}
           {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md text-center">
                        <h3 className="text-lg font-semibold mb-4">This feature is not yet available</h3>
                        <p className="text-gray-700 mb-4">
                            Please contact us directly through:
                        </p>
                        <div className="flex justify-center space-x-6 mb-4">
                            <a
                                href="https://www.facebook.com/profile.php?id=100008542713746"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sky-500 text-4xl hover:text-sky-700 transition-transform transform hover:scale-110"
                            >
                                <FontAwesomeIcon icon={faFacebook} />
                            </a>
                            <a
                                href="https://zalo.me/0908802074"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sky-500 text-4xl hover:text-sky-700 transition-transform transform hover:scale-110"
                            >
                                <FontAwesomeIcon icon={faFacebookMessenger} />
                            </a>
                        </div>
                        <button
                            onClick={closePopup}
                            className="px-4 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-600"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            {/* Middle Column: Website Architecture Links with Icons */}
            <div className="flex flex-col items-center md:items-center">
                <h2 className="text-lg font-semibold  mb-4">Website Architecture</h2>
                <ul className="space-y-3 text-start">
                    <li className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faCogs} className="text-black" />
                        <a href="#services" className="text-gray-700 hover: transition-colors">Services</a>
                    </li>
                    <li className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faInfoCircle} className="text-black" />
                        <a href="#about" className="text-gray-700 hover: transition-colors">About Us</a>
                    </li>
                    <li className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faThumbsUp} className="text-black" />
                        <a href="#whychooseus" className="text-gray-700 hover: transition-colors">Why Choose Us</a>
                    </li>
                    <li className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faStar} className="text-black" />
                        <a href="#reviews" className="text-gray-700 hover: transition-colors">Reviews</a>
                    </li>
                    <li className="flex items-center space-x-2">
                        <FontAwesomeIcon icon={faProjectDiagram} className="text-black" />
                        <a href="#projects" className="text-gray-700 hover: transition-colors">Projects</a>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
