import React, { useEffect, useState } from "react";
import { FiPhone, FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

function Navbar() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const handleScrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
            setIsSidebarOpen(false); // Đóng sidebar khi nhấn vào link trong mobile
        }
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        // Lấy phần tử .hero-content
        const heroElement = document.querySelector(".hero-content");

        const handleScroll = () => {
            if (heroElement.scrollTop > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        // Kiểm tra phần tử tồn tại trước khi gắn sự kiện
        if (heroElement) {
            heroElement.addEventListener("scroll", handleScroll);
        }

        // Dọn dẹp sự kiện khi component bị huỷ
        return () => {
            if (heroElement) {
                heroElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <>
            {/* PC Navbar giữ nguyên */}
            <header
                className={`${scrolled ? "bg-white border-b border-gray-200" : "bg-transparent"}
                    navbar-transition text-white w-full h-24 flex items-center px-12 fixed top-0 z-50 hidden md:flex
                `}
            >
                <div className="flex items-center ml-10">
                    <img src="https://hopee.com.vn/wp-content/themes/hopee_blog/assets/front/images/logo-black.svg" alt="Logo" className="h-16 w-36" />
                </div>
                <nav className="ml-auto flex space-x-6 font-poppins text-lg tracking-wide uppercase text-black">
                    <a
                        onClick={() => handleScrollToSection("services")}
                        href="#"
                        className="relative text-black hover:text-gray-800 transition-colors duration-300 after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-0.5 after:bg-gray-800 after:w-full after:scale-x-0 after:origin-left hover:after:scale-x-100 after:transition-transform after:duration-300"
                    >
                        Services
                    </a>
                    <a
                        onClick={() => handleScrollToSection("about")}
                        href="#"
                        className="relative text-black hover:text-gray-800 transition-colors duration-300 after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-0.5 after:bg-gray-800 after:w-full after:scale-x-0 after:origin-left hover:after:scale-x-100 after:transition-transform after:duration-300"
                    >
                        About Us
                    </a>
                    <a
                        onClick={() => handleScrollToSection("projects")}
                        href="#"
                        className="relative text-black hover:text-gray-800 transition-colors duration-300 after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-0.5 after:bg-gray-800 after:w-full after:scale-x-0 after:origin-left hover:after:scale-x-100 after:transition-transform after:duration-300"
                    >
                        Projects
                    </a>
                    <a
                        onClick={() => handleScrollToSection("reviews")}
                        href="#"
                        className="relative text-black hover:text-gray-800 transition-colors duration-300 after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-0.5 after:bg-gray-800 after:w-full after:scale-x-0 after:origin-left hover:after:scale-x-100 after:transition-transform after:duration-300"
                    >
                        Reviews
                    </a>
                    <a
                        onClick={() => handleScrollToSection("contact")}
                        href="#"
                        className="relative text-black hover:text-gray-800 transition-colors duration-300 after:content-[''] after:absolute after:left-0 after:bottom-0 after:h-0.5 after:bg-gray-800 after:w-full after:scale-x-0 after:origin-left hover:after:scale-x-100 after:transition-transform after:duration-300"
                    >
                        Contact Us
                    </a>
                </nav>
            </header>

            {/* Mobile Navbar */}
            <header className=
                {`
                    ${scrolled ? 'bg-white border-b border-gray-200' : 'bg-transparent'}
                    navbar-transition md:hidden text-white w-full h-16 flex items-center px-8 fixed top-0 z-50
                `}
            >
                <div className="flex items-center">
                    <img src="https://hopee.com.vn/wp-content/themes/hopee_blog/assets/front/images/logo-black.svg" alt="Logo" className="h-12 w-28" />
                </div>
                <div className="ml-auto flex items-center space-x-6">
                    <a target="_blank" href="https://zalo.me/0908802074" className="text-black">
                        <FiPhone size={24} />
                    </a>
                    <button onClick={toggleSidebar} className="text-black focus:outline-none">
                        <FiMenu size={30} />
                    </button>
                </div>
            </header>

            {/* Sidebar for Mobile */}
            <div
                className={`fixed top-0 left-0 w-64 h-full bg-white text-black z-50 transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
                    } transition-transform duration-300 ease-in-out shadow-lg md:hidden`}
            >
                <div className="flex items-center justify-between h-16 p-4 border-b border-gray-200">
                    <img src="https://hopee.com.vn/wp-content/themes/hopee_blog/assets/front/images/logo-black.svg" alt="Logo" className="h-12 w-28" />
                    <button onClick={toggleSidebar} className="text-black focus:outline-none">
                        <AiOutlineClose size={30} />
                    </button>
                </div>
                <nav className="flex flex-col space-y-6 mt-8 font-poppins text-lg tracking-wide uppercase">
                    <a
                        href="#"
                        className="px-6 py-2 hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => handleScrollToSection("services")}
                    >
                        Services
                    </a>
                    <a
                        href="#"
                        className="px-6 py-2 hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => handleScrollToSection("about")}
                    >
                        About Us
                    </a>
                    <a
                        href="#"
                        className="px-6 py-2 hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => handleScrollToSection("projects")}
                    >
                        Projects
                    </a>
                    <a
                        href="#"
                        className="px-6 py-2 hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => handleScrollToSection("reviews")}
                    >
                        Reviews
                    </a>
                    <a
                        href="#"
                        className="px-6 py-2 hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => handleScrollToSection("contact")}
                    >
                        Contact Us
                    </a>
                </nav>
            </div>

            {/* Overlay */}
            {isSidebarOpen && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-40"
                    onClick={toggleSidebar} // Đóng sidebar khi nhấp vào overlay
                ></div>
            )}
        </>
    );
}

export default Navbar;
