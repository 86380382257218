import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

function AboutUs() {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: false }); // Đặt 'once: false' để chạy lại khi cuộn qua

    return (
        <section ref={ref} className="bg-peach-100 py-8 md:py-16 md:px-8 overflow-hidden" id="about">
            <motion.div
                className="container mx-auto flex flex-col lg:flex-row items-center space-y-8 lg:space-y-0 lg:space-x-8"
                initial={{ opacity: 0, y: 30 }}
                animate={isInView ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, ease: "easeOut" }}
            >
                {/* Left Text Section */}
                <motion.div
                    className="lg:w-1/2 space-y-6 text-center lg:text-left"
                    initial={{ opacity: 0, x: -20 }}
                    animate={isInView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 1, ease: "easeOut", delay: 0.2 }}
                >
                    <p className="text-orange-500 uppercase font-semibold tracking-widest">
                        Who We Are
                    </p>
                    <h2 className="text-2xl md:text-4xl font-bold text-gray-900 leading-tight">
                        Use professional web and mobile app developers for your project
                    </h2>
                    <p className="text-gray-700 leading-relaxed text-justify">
                        Founded in 2022, our company has several years of experience in the American market, fostering strong partnerships with over 10 American companies. We provide expert solutions to enhance their business capabilities and develop successful software products.
                    </p>
                    <p className="text-gray-700 leading-relaxed text-justify">
                        Our company boldly takes on global challenges, delivering digital products to companies worldwide.
                    </p>
                    <button className="mt-6 bg-sky-500 text-white px-6 py-2 rounded-full font-semibold hover:bg-sky-600 transition duration-300">
                        Get Started
                    </button>
                </motion.div>

                {/* Right Image Section */}
                <motion.div
                    className="lg:w-1/2 flex justify-center lg:justify-end"
                    initial={{ opacity: 0, x: 20 }}
                    animate={isInView ? { opacity: 1, x: 0 } : {}}
                    transition={{ duration: 1, ease: "easeOut", delay: 0.4 }}
                >
                    <div className="flex space-x-6 flex-col lg:flex-row">
                        <img
                            src="/assets/images/web-dev.webp"
                            alt="Office"
                            className="img-bottom rounded-lg shadow-lg w-full h-auto lg:max-w-72 lg:h-full relative top-8 lg:top-0"
                        />
                        <img
                            src="/assets/images/original.webp"
                            alt="Presentation"
                            className="img-top rounded-lg shadow-lg w-full h-auto lg:max-w-72 lg:h-full relative -top-6 lg:top-0"
                        />
                    </div>
                </motion.div>
            </motion.div>
        </section>
    );
}

export default AboutUs;
