import React, { useState } from "react";
import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import Hero from "./components/layout/Hero";
import "./App.css";

function App() {
    const [bgColor, setBgColor] = useState("bg-transparent"); // Màu nền mặc định là trong suốt
    const [iconColor, setIconColor] = useState("white"); // Màu mặc định cho icon

    return (
        <div style={{ overflowX: "hidden" }} id="app">
            <Navbar bgColor={bgColor} />
            <div className="flex">
                <div className="hidden sm:block">
                    <Sidebar bgColor={bgColor} iconColor={iconColor} />
                </div>
                <Hero setBgColor={setBgColor} setIconColor={setIconColor} />
            </div>
        </div>
    );
}

export default App;
