import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

const WhyChooseUs = () => {
	const cards = [
		{
			imgSrc: "https://hopee.com.vn/wp-content/themes/hopee_blog/assets/front/images/icons/analyz.png",
			title: "Analyze Project",
			description: "We prioritize our customers. The initial objective is to comprehensively understand your needs."
		},
		{
			imgSrc: "https://img.icons8.com/ios/50/customer-support.png",
			title: "Great Support",
			description: "We prioritize studying your project, company, and future goals. You can always rely on our prompt and friendly support."
		},
		{
			imgSrc: "https://hopee.com.vn/wp-content/themes/hopee_blog/assets/front/images/icons/idea.png",
			title: "Smart Ideas",
			description: "We propose digital solutions tailored to meet your requirements, bringing the project envisioned in your mind to fruition."
		},
		{
			imgSrc: "https://hopee.com.vn/wp-content/themes/hopee_blog/assets/front/images/icons/optimization.png",
			title: "Optimization",
			description: "We optimize functionality and effectiveness, excelling in the delivery of software and IT solutions."
		},
	];

	const ref = useRef(null);
	const isInView = useInView(ref, { once: false });

	return (
		<motion.div
			ref={ref}
			className="why-choose py-8 sm:py-12 bg-gray-100 text-center overflow-x-hidden"
			initial={{ opacity: 0 }} // Bắt đầu với opacity để tránh cuộn ngang
			animate={isInView ? { opacity: 1 } : { opacity: 0 }} // Chỉ thay đổi opacity khi vào viewport
			transition={{ duration: 1.5, ease: "easeOut" }}
			id='whychooseus'
		>
			<h2 className="text-2xl sm:text-4xl font-bold text-gray-800 mb-6 sm:mb-10 p-1 md:p-0">
				Why Choose Us For Your Next Project?
			</h2>
			<div className="cards flex flex-col sm:flex-row flex-wrap justify-center items-center gap-6 sm:gap-8 w-full overflow-hidden p-4 md:p-2">
				{cards.map((card, index) => (
					<motion.div
						key={index}
						className="card bg-white rounded-lg shadow-lg p-4 sm:p-6 w-full sm:w-64 text-left overflow-hidden"
						initial={{ opacity: 0, y: 50 }} // Sử dụng di chuyển dọc
						animate={isInView ? { opacity: 1, y: 0 } : {}}
						transition={{ duration: 1.5, ease: "easeOut", delay: index * 0.2 }}
					>
						<img src={card.imgSrc} alt={`${card.title} Icon`} className="w-8 sm:w-10 mb-3 sm:mb-4" />
						<h3 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-1 sm:mb-2">{card.title}</h3>
						<p className="text-gray-600 text-sm sm:text-base">{card.description}</p>
					</motion.div>
				))}
			</div>
		</motion.div>
	);
};

export default WhyChooseUs;
