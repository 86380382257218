import React, { useEffect, useState } from "react";
import { FaFacebook, FaFacebookMessenger, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";

function Sidebar() {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        // Lấy phần tử .hero-content
        const heroElement = document.querySelector(".hero-content");

        const handleScroll = () => {
            if (heroElement.scrollTop > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        // Kiểm tra phần tử tồn tại trước khi gắn sự kiện
        if (heroElement) {
            heroElement.addEventListener("scroll", handleScroll);
        }

        // Dọn dẹp sự kiện khi component bị huỷ
        return () => {
            if (heroElement) {
                heroElement.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <div
            className={`${scrolled ? "bg-white border-r border-gray-200" : "bg-transparent"} 
                navbar-transition w-16 h-[calc(100vh-6rem)] fixed top-24 flex flex-col items-center justify-center space-y-6 z-30
            `}
        >
            <a target="_blank" href="https://www.facebook.com/profile.php?id=100008542713746"><FaFacebook className="w-6 h-6 text-black bg-transparent" /></a>
            <a target="_blank" href="https://www.linkedin.com/in/nguy%E1%BB%85n-kh%C3%A1nh-985889232"><FaLinkedin className="w-6 h-6 text-black bg-transparent" /></a>
            <a target="_blank" href="https://zalo.me/0908802074"><FaFacebookMessenger className="w-6 h-6 text-black bg-transparent" /></a>
            <a href="#"><FaTwitter className="w-6 h-6 text-black bg-transparent" /></a>
            <a href="#"><FaYoutube className="w-6 h-6 text-black bg-transparent" /></a>
        </div>
    );
}

export default Sidebar;
