import React, { useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Navigation } from "swiper/modules";
import 'swiper/css/navigation';
import Stars from './Stars.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const reviews = [
    {
        id: 1,
        name: "BD Home Group",
        title: "Web Development Project",
        star: 5,
        content:
            "The team was incredibly professional and responsive. They delivered a website that exceeded our expectations in terms of design and functionality. Their attention to detail and ability to bring our vision to life was impressive."
    },
    {
        id: 2,
        name: "Vietnamese Food Restaurant",
        title: "Web Development Project",
        star: 5,
        content:
            "We are thrilled with the website created for our restaurant. It perfectly captures the essence of our brand and provides a great user experience for our customers. The development team was a joy to work with."
    },
    {
        id: 3,
        name: "Anh Duong Logistics",
        title: "Web Development Project",
        star: 5,
        content:
            "The website they built for us truly reflects the professionalism and scale of our logistics services. The team was attentive, skilled, and committed to delivering a user-friendly and visually stunning platform."
    },
    {
        id: 4,
        name: "Spa Booking System",
        title: "Web & App Development Project",
        star: 5,
        content:
            "The booking system they developed has been a game-changer for our spa. It’s easy to use for both customers and staff. The team was communicative and ensured that every detail aligned with our needs."
    },
    {
        id: 5,
        name: "Management Machine Workshop",
        title: "Web App Development Project",
        star: 5,
        content:
            "Their custom web app streamlined our workshop processes and improved efficiency significantly. The team was professional, knowledgeable, and went above and beyond to ensure the final product was exactly what we needed."
    },
];

const Review = () => {
    // Refs for navigation buttons
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    return (
        <div className="mx-auto p-6 mb-6 mt-2" id="reviews">
            <h1 className="text-2xl font-bold text-center mb-8">Customer Reviews</h1>
            <Swiper
                loop={true}
                breakpoints={{
                    340: {
                        slidesPerView: 1,
                    },
                    700: {
                        slidesPerView: 3,
                        spaceBetween: 15
                    }
                }}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                }}
                onInit={(swiper) => {
                    // Assign navigation buttons once swiper is initialized
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    swiper.navigation.init();
                    swiper.navigation.update();
                }}
                modules={[Autoplay, Navigation]}
            >
                {reviews.map((r) => (
                    <SwiperSlide key={r?.id}>
                        <div className="p-2 cursor-pointer lg:p-12">
                            <div className="flex space-y-4 flex-col items-center justify-center text-center">
                                <div className="flex flex-col">
                                    <h1 className="text-2xl">{r?.name}</h1>
                                </div>
                                <Stars len={r?.star} />
                                <div className="font-newFont text-black/80">
                                    <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            className="inline-block h-7 w-7 pr-2"
                                            viewBox="0 0 24 24">
                                            <path
                                                d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                                        </svg>
                                        {r?.content}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Navigation buttons below the Swiper */}
            <div className="flex justify-center space-x-4 mt-4">
                <button ref={prevRef} className="bg-gray-200 rounded-full p-3 hover:bg-gray-300">
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button ref={nextRef} className="bg-gray-200 rounded-full p-3 hover:bg-gray-300">
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
        </div>
    );
};

export default Review;
